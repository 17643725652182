import { useRef, useState, useEffect } from "react";
import parse from 'html-react-parser';
import { fetchCockpitData } from "./utils/cockpit";
import socketIOClient from "socket.io-client";
import LanguagePicker from './components/LanguagePicker';

const ENDPOINT = 'https://nodebenditto.dxrzonestaging.co.uk';

const App = () => {
  const [siteData, setSiteData] = useState({});
  const [signaturesCount, setSignaturesCount] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const [backgroundColor, setBackgroundColor] = useState('');
  const [foregroundColor, setForegroundColor] = useState('');
  const [linkIsCopied, setLinkIsCopied] = useState(false);
  const { windowWidth, windowHeight } = windowDimensions;
  const openLetter = useRef();
  const [isInitialised, setIsInitialised] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(0);
  const [languagePickerIsActive, setLanguagePickerIsActive] = useState(false);

  const socket = socketIOClient(ENDPOINT, {
    withCredentials: true,
    extraHeaders: {
      "my-custom-header": "my-custom-header"
    }
  });

  const handleClickCopy = () => {
    const copyText = document.createElement('input');
    copyText.type = 'text';
    copyText.value = window.location.href;
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    navigator.clipboard.writeText(copyText.value);
    setLinkIsCopied(true);
    setTimeout(() => {
      setLinkIsCopied(false);
    }, 2400);
  }

  useEffect(() => {
    if (isInitialised === false) {
      socket.connect();
      setIsInitialised(true);
    }
  }, [socket, isInitialised]);

  useEffect(() => {
    if (!siteData.signLinkUrl) {
      let cumulativeSignaturesCount = 0;
      const handleGetSignatures = (response) => {
        if (response.entries) {
          cumulativeSignaturesCount = response.entries.length;
          setSignaturesCount(cumulativeSignaturesCount);
        }
      }

      const handleGetSiteData = (response) => {
        setSiteData(response);
        if (response.backgroundColor) {
          document.body.style.backgroundColor = response.backgroundColor;
          document.querySelector('html').style.backgroundColor = response.backgroundColor;
          setBackgroundColor(response.backgroundColor);
        }
        if (response.foregroundColor) {
          document.body.style.color = response.foregroundColor;
          setForegroundColor(response.foregroundColor);
        }
      }

      fetchCockpitData('siteOptions', handleGetSiteData, false);
      fetchCockpitData('signatures', handleGetSignatures, true);

      socket.on("signature added", () => {
        console.log("signature added");
        cumulativeSignaturesCount++;
        setSignaturesCount(cumulativeSignaturesCount);
      });
    }
  }, [socket, siteData.signLinkUrl]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div
      className="main"
      style={{
        width: windowDimensions.windowWidth + 'px',
        height: windowDimensions.windowHeight + 'px'
      }}
    >
      {
        siteData.openLetterTranslations && siteData.openLetterTranslations[1] &&
        <LanguagePicker
          activeLanguage={activeLanguage}
          setActiveLanguage={setActiveLanguage}
          languagePickerIsActive={languagePickerIsActive}
          setLanguagePickerIsActive={setLanguagePickerIsActive}
          siteData={siteData}
          windowDimensions={windowDimensions}
        />
      }
      {
        siteData.openLetterTranslations &&
        siteData.openLetterTranslations[activeLanguage] &&
        siteData.openLetterTranslations[activeLanguage].value?.openLetter &&
        <div
          className={`open-letter${siteData.openLetterTranslations[activeLanguage].value.rtl ? ' rtl' : ''}`}
          style={{
            width: windowWidth + 'px',
            maxHeight: windowHeight + 'px'
          }}
          ref={openLetter}
        >
          {parse(siteData.openLetterTranslations[activeLanguage].value.openLetter)}
        </div>
      }
      {
        siteData.copyButtonText &&
        <button
          className="copy-button"
          aria-label="Copy link to clipboard"
          onClick={handleClickCopy}
        >{siteData.copyButtonText}</button>
      }
      {
        linkIsCopied === true && siteData.linkCopiedMessage &&
        <p
          className="link-copied-message"
          style={{
            background: backgroundColor,
            borderColor: foregroundColor
          }}
        >{siteData.linkCopiedMessage}</p>
      }
      {
        siteData.instagramHandle && siteData.instagramLinkText &&
        <a
          className="instagram-link"
          href={`https://instagram.com/${siteData.instagramHandle.replace('@', '')}`}
          rel="noreferrer"
          target="_blank"
        >{siteData.instagramLinkText}</a>
      }
      {
        signaturesCount > 0 &&
        <p
          className="text--signatures-count"
          style={{ backgroundColor: backgroundColor, color: foregroundColor, borderColor: foregroundColor }}
        >{signaturesCount} signed</p>
      }
      {
        siteData.signLinkUrl && siteData.signLinkText &&
        <a
          className="sign-link"
          href={siteData.signLinkUrl}
          rel="noreferrer"
          target="_blank"
        >{siteData.signLinkText}</a>
      }
    </div>
  );
}

export default App;
