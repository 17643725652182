import { useState } from 'react';

const LanguagePickerButton = (props) => {

  const { activeLanguage, setActiveLanguage, setLanguagePickerIsActive, siteData, i, translation } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className={`language-picker__language__button${activeLanguage === i ? ' active' : ''}`}
      onClick={() => {
        setLanguagePickerIsActive(false);
        setActiveLanguage(i);
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onBlur={() => {
        setIsHovered(false);
      }}
      style={{
        backgroundColor: activeLanguage === i || isHovered === true ? siteData.foregroundColor : siteData.backgroundColor,
        borderColor: siteData.foregroundColor,
        color: activeLanguage === i || isHovered === true ? siteData.backgroundColor : siteData.foregroundColor
      }}
    >{translation.value?.language.toUpperCase()}</button>
  )
}

const LanguagePicker = (props) => {

  const { languagePickerIsActive, setLanguagePickerIsActive, siteData, windowDimensions } = props;

  if (languagePickerIsActive === false) {
    return (
      <button
        className="button--language-picker"
        onClick={() => setLanguagePickerIsActive(true)}
      >{siteData.languagePickerButtonText}</button>
    );
  } else {
    return (
      <ul
        className="language-picker"
        style={{
          height: windowDimensions.windowHeight + 'px',
          backgroundImage: `linear-gradient(90deg, ${siteData.backgroundColor.replace(')', ', 0)')} 0%, ${siteData.backgroundColor} 20%)`
        }}
      >
      {
        siteData.openLetterTranslations.map(
          (translation, i) => (
            <li
              key={i}
              className="language-picker__language"
            >
            <LanguagePickerButton translation={translation} i={i} {...props} />
            </li>
          )
        )
      }
      </ul>
    )
  }
}

export default LanguagePicker;